import { useState } from "react";

// TODO: FUTURE: arrow syntax that shows props? {data, value ...etc., where did I see this }
// TODO: real feature flags
function Search(props) {

  const [searchValue, setSearchValue] = useState([]);

  const handleInputChange = e => {
    setSearchValue(e.target.value);
  }

  const data = props.data;

  const verseShouldDisplay = () => {
    return true;
  };

  const renderSearchResults = () => {
    // TODO: just an expression?  
    return (
      data["Torah"].map(el => {
        return (
          <div key={el.book_name}>
            {/* hide book name since it's always in drop down <h1 key={el.id}>{el.book_name} {el.sub_book_name}</h1> */}
            {Object.keys(el.chapters).map(ch => {
              return (
                <div key={ch}>
                  {Object.keys(el.chapters[ch]).map(hebrewVerseNumber => {
                    const verseText = el.chapters[ch][hebrewVerseNumber];
                    if (verseText.indexOf(searchValue) > -1) {

                      return (
                        <div key={hebrewVerseNumber}>
                          <div className="row mb-0">
                            <div className="col-sm pb-2">
                              <h3>{el.book_name} {ch}:{hebrewVerseNumber}</h3>
                              <div className="mx-4">{el.chapters[ch][hebrewVerseNumber]}</div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  }
                  )}
                </div>
              )
            })}
          </div>
        );
      })
    )
  }

  return (
    <>
      <div className="py-3">
        <p>This is an experimental feature, and currently works for Torah only (not megillot)!</p>
        <input type="text" value={searchValue} onChange={handleInputChange}></input>
      </div>
      <div>
        You searched for '{searchValue}'

        {
          data["Torah"] ? renderSearchResults() : "Loading..."
        }

      </div>
    </>
  );
}

export default Search;