import './MainMenu.css';
import Logo from './logo.png';

import { useState } from 'react';
import { CSSTransition } from 'react-transition-group';

function MainMenu(props) {

  const [isOpen, setIsOpen] = useState(false); // TODO: should this be inside the component?
  const [didCopy, setDidCopy] = useState(false); // TODO: should this be inside the component?

  function handleClick() {
    setIsOpen(isOpen ? false : true);
  }

  function handleInputClick(evt) {
    evt.target.select();
  }

  function handleCopyButtonClick() {
    document.getElementById("share-url-input").select();
    document.execCommand('copy');
    setDidCopy(true);
    setTimeout(() => { setDidCopy(false) }, 3000);
  }

  return (
    <CSSTransition in={isOpen} timeout={600} classNames="main-menu">
      <div className={'main-menu' + (isOpen ? ' menu-open' : '')}>
        <button onClick={handleClick}>
          <img src={Logo} height="72"></img>
        </button>
        <div className="menu-body container" dir="ltr">
          <div className='menu-section'>
            <span className="parshio-bigger">Parsh.io</span> is a smart way to learn a Torah
            reading. Parsh.io makes it easy to focus on exactly what you need and practice regularly.
            {/* TODO <br /><a href="https://www.marcstober.com/fmv/TODO">More about parsh.io</a> */}
          </div>
          <div className='menu-section'>
            <i className="bi bi-arrow-up-right-square"></i>{"  "}
            Share this reading:
            <div id="copied-notification-wrapper" className={didCopy ? '' : 'd-none'}>
              <div id="copied-notification">
                <div id="copied-notification-inner">Copied!</div>
              </div>
            </div>
            <div className="input-group input-group-sm">
              <input id="share-url-input" type="text" className="form-control" value={document.location.href} onClick={handleInputClick} />
              <div className="input-group-append">
                <button type="button" className="btn btn-secondary" onClick={handleCopyButtonClick}><i className="bi bi-clipboard"></i></button>
              </div>
            </div>
          </div>
          <div className='menu-section'>
            Resources<br />
            <ul>
              <li><a href="/trope/torah.html">Jacobson Trope</a></li>
            </ul>
          </div>
          <div className='menu-section'>
            Questions, comments, need help? Contact <a href="mailto:shalom@parsh.io">shalom@parsh.io</a>.
          </div>
          <div className="menu-section parshio-smaller">
            Created by <a href="https://www.marcstober.com/fmv" target="_blank">Cantor Marc Stober</a>.<br /><br />

            The Hebrew Bible text is
            from <a href="https://he.m.wikisource.org/wiki/%D7%9E%D7%A9%D7%AA%D7%9E%D7%A9:Dovi/%D7%9E%D7%A7%D7%A8%D7%90_%D7%A2%D7%9C_%D7%A4%D7%99_%D7%94%D7%9E%D7%A1%D7%95%D7%A8%D7%94#%D7%A8%D7%90%D7%A9" target="_blank"><i>Miqra al pi HaMesorah</i></a> by
            Rabbi Seth &ldquo;Avi&rdquo; Kaddish with technical help from Benjamin Denckla,
            and is under a <a href="https://creativecommons.org/licenses/by-sa/3.0/deed" target="_blank">Creative Commons Attribution-ShareAlike (CC BY-SA) 3.0</a> license.
          </div>
        </div>
      </div>
    </CSSTransition>
  );
}

export default MainMenu;
