import adjustSelection from "./adjustSelection"

// TODO: FUTURE: arrow syntax that shows props? {data, value ...etc., where did I see this }
function Selector(props) {
    const handleChange = (event) => {
        const newSelection = {
            bookName: props.selection.bookName,
            fromChapter: props.selection.fromChapter,
            fromVerse: props.selection.fromVerse,
            toChapter: props.selection.toChapter,
            toVerse: props.selection.toVerse,
        }

        newSelection[event.target.name] = event.target.value
        newSelection.targetName = event.target.name

        adjustSelection(event.target.name, newSelection, data)

        props.onSelection(newSelection)
    }

    const data = props.data

    console.log(props.selection, data)
    console.log(
        props.selection.toChapter,
        props.selection.toVerse,
        props.selection.fromVerse,
        props.selection.fromChapter
    )
    console.log("targetName", props.selection.targetName)

    if (props.selection.targetName) {
        console.log("adjusting selection...")
        adjustSelection(props.selection.targetName, props.selection, data)
    }

    function getChapterNumbers(bookName) {
        let chapterNumbers = []
        data.forEach((el) => {
            if (bookName === el.book_name) {
                chapterNumbers = Object.keys(el.chapters)
            }
        })
        return chapterNumbers
    }

    function getVerseNumbers(bookName, chapterNumber) {
        let verseNumbers = []
        data.forEach((el) => {
            if (bookName === el.book_name) {
                verseNumbers = Object.keys(el.chapters[chapterNumber])
            }
        })
        return verseNumbers
    }

    return (
        <>
            <div className="row gx-1 mb-2">
                <div className="col-auto">
                    <select
                        onChange={handleChange}
                        name="bookName"
                        value={props.selection.bookName}
                        className="form-select form-select-lg"
                    >
                        {props.books.map((el, i) =>
                            el.books.map((book, j) => (
                                <>
                                    {i > 0 && j === 0 ? <hr></hr> : ""}
                                    <option key={book.hebrewName}>
                                        {book.hebrewName}
                                    </option>
                                </>
                            ))
                        )}
                    </select>
                </div>
            </div>
            <div className="row gx-1 align-items-center ">
                <div className="col-3 col-sm-auto">
                    <select
                        onChange={handleChange}
                        name="fromChapter"
                        value={props.selection.fromChapter}
                        className="form-select form-select-lg"
                    >
                        {getChapterNumbers(props.selection.bookName).map(
                            (ch) => {
                                return <option key={ch}>{ch}</option>
                            }
                        )}
                    </select>
                </div>
                <div className="col-3 col-sm-auto">:</div>
                <div className="col-3 col-sm-auto">
                    <select
                        onChange={handleChange}
                        name="fromVerse"
                        value={props.selection.fromVerse}
                        className="form-select form-select-lg"
                    >
                        {getVerseNumbers(
                            props.selection.bookName,
                            props.selection.fromChapter
                        ).map((hebrewVerseNumber) => {
                            return (
                                <option key={hebrewVerseNumber}>
                                    {hebrewVerseNumber}
                                </option>
                            )
                        })}
                    </select>
                </div>
                <div className="col-3 col-sm-auto">&ndash;</div>
                <div className="col-3 col-sm-auto">
                    <select
                        onChange={handleChange}
                        name="toChapter"
                        value={props.selection.toChapter}
                        className="form-select form-select-lg"
                    >
                        {getChapterNumbers(props.selection.bookName).map(
                            (ch) => {
                                return <option key={ch}>{ch}</option>
                            }
                        )}
                    </select>
                </div>
                <div className="col-3 col-sm-auto">:</div>
                <div className="col-3 col-sm-auto">
                    <select
                        onChange={handleChange}
                        name="toVerse"
                        value={props.selection.toVerse}
                        className="form-select form-select-lg"
                    >
                        {getVerseNumbers(
                            props.selection.bookName,
                            props.selection.toChapter
                        ).map((hebrewVerseNumber) => {
                            return (
                                <option key={hebrewVerseNumber}>
                                    {hebrewVerseNumber}
                                </option>
                            )
                        })}
                    </select>
                </div>
            </div>
        </>
    )
}

export default Selector
