import { hebrew2int } from "./services/gematria"

import { useState, useEffect } from "react"

import Popup from "reactjs-popup" // https://blog.logrocket.com/5-best-popover-libraries-react/

// https://wiki.crosswire.org/OSIS_Book_Abbreviations
// FUTURE: separate module (or even package, maybe one exists?)
const osisBookNames = {
    "ספר בראשית": "Gen",
    "ספר שמות": "Exod",
    "ספר ויקרא": "Lev",
    "ספר במדבר": "Num",
    "ספר דברים": "Deut",
    "ספר יהושע": "Josh",
    "ספר שופטים": "Judg",
    "ספר שמואל א": "1Sam",
    "ספר שמואל ב": "2Sam",
    "ספר מלכים א": "1Kgs",
    "ספר מלכים ב": "2Kgs",
    "ספר ישעיה": "Isa",
    "ספר ירמיה": "Jer",
    "ספר יחזקאל": "Ezek",
    "ספר הושע": "Hos",
    "ספר יואל": "Joel",
    "ספר עמוס": "Amos",
    "ספר עובדיה": "Obad",
    "ספר יונה": "Jonah",
    "ספר מיכה": "Mic",
    "ספר נחום": "Nah",
    "ספר חבקוק": "Hab",
    "ספר צפניה": "Zeph",
    "ספר חגי": "Hag",
    "ספר זכריה": "Zech",
    "ספר מלאכי": "Mal",
    "ספר תהילים": "Ps",
    "ספר איוב": "Job",
    "ספר משלי": "Prov",
    "ספר רות": "Ruth",
    "ספר שיר השירים": "Song",
    "ספר קהלת": "Eccl",
    "ספר איכה": "Lam",
    "ספר אסתר": "Esth",
    "ספר דניאל": "Dan",
    "ספר עזרא": "Ezra",
    "ספר נחמיה": "Neh",
    "ספר דברי הימים א": "1Chr",
    "ספר דברי הימים ב": "2Chr",
}

function MainText({ el, selection, transliteratedBookNames }) {
    const [notesData, setNotesData] = useState([])
    const [mData, setMData] = useState()

    // TODO: DEBT: unit test this
    const verseShouldDisplay = (
        selection,
        hebrewChapterNumber,
        hebrewVerseNumber
    ) => {
        const selectedFromChapterNumberInt = hebrew2int(selection.fromChapter)
        const selectedFromVerseNumberInt = hebrew2int(selection.fromVerse)
        const selectedToChapterNumberInt = hebrew2int(selection.toChapter)
        const selectedToVerseNumberInt = hebrew2int(selection.toVerse)
        const thisChapterNumberInt = hebrew2int(hebrewChapterNumber)
        const thisVerseNumberInt = hebrew2int(hebrewVerseNumber)

        // if it's anywhere in between the selected chapters, display it
        if (
            thisChapterNumberInt > selectedFromChapterNumberInt &&
            thisChapterNumberInt < selectedToChapterNumberInt
        ) {
            return true
        }

        // if only selected verses from 1 chapter
        if (selectedFromChapterNumberInt === selectedToChapterNumberInt) {
            // if it's in between selected verse numbers, display it
            if (
                thisChapterNumberInt === selectedFromChapterNumberInt &&
                thisVerseNumberInt >= selectedFromVerseNumberInt &&
                thisVerseNumberInt <= selectedToVerseNumberInt
            ) {
                return true
            }
            // otherwise don't
            return false
        }

        // if selection spans multiple chapters:

        // if it's in the from chapter and greater than or equal to from verse, display it
        if (
            thisChapterNumberInt === selectedFromChapterNumberInt &&
            thisVerseNumberInt >= selectedFromVerseNumberInt
        ) {
            return true
        }

        // if it's in the to chapter and less than or equal to to verse, display it
        if (
            thisChapterNumberInt === selectedToChapterNumberInt &&
            thisVerseNumberInt <= selectedToVerseNumberInt
        ) {
            return true
        }

        return false
    }

    function getNotes(hebrewBookName, hebrewChapterNumber, hebrewVerseNumber) {
        if (!notesData) return
        const bookNotes = notesData[transliteratedBookNames[hebrewBookName]]
        if (!bookNotes) return
        const chapterNotes =
            bookNotes[hebrew2int(hebrewChapterNumber).toString()]
        if (!chapterNotes) return
        const verseNotes =
            chapterNotes[hebrew2int(hebrewVerseNumber).toString()]
        return verseNotes
    }

    function getNotesElement(
        hebrewBookName,
        hebrewChapterNumber,
        hebrewVerseNumber
    ) {
        const notes = getNotes(
            hebrewBookName,
            hebrewChapterNumber,
            hebrewVerseNumber
        )
        if (notes) {
            return (
                <div className="row mb-0">
                    <div className="col-sm" dir="ltr">
                        <div className="alert alert-warning mx-4">
                            {notes.map((note) => (
                                <div>{note}</div>
                            ))}
                        </div>
                    </div>
                </div>
            )
        }
    }

    useEffect(() => {
        fetch(`${process.env.PUBLIC_URL}/notes.json`)
            .then((response) => response.json())
            .then((data) => {
                setNotesData(data)
            })
    }, [])

    useEffect(() => {
        const osisBookName = osisBookNames[el.book_name]
        fetch(`${process.env.PUBLIC_URL}/morphhb/${osisBookName}.xml`)
            .then((response) => response.text())
            .then((data) => {
                const parser = new DOMParser()
                const xmlDoc = parser.parseFromString(data, "text/xml")
                setMData(xmlDoc)
            })
    }, [])

    function getMeaning(
        hebrewBookName,
        hebrewChapterNumber,
        hebrewVerseNumber
    ) {
        if (!mData) return
        const osisBookName = osisBookNames[hebrewBookName]
        const verseMeanings = mData.getElementsByTagName("verse")
        const selectedVerseMeanings = Array.from(verseMeanings).filter(
            (verse) =>
                // TODO: use ints internally instead of having to call hebrew2int so many times
                {
                    const osisID = `${osisBookName}.${hebrew2int(
                        hebrewChapterNumber
                    )}.${hebrew2int(hebrewVerseNumber)}`
                    return verse.getAttribute("osisID") === osisID
                }
        )
        return Array.from(
            selectedVerseMeanings[0].getElementsByTagName("w")
        ).map((child) => {
            return child.getAttribute("lemma")
        })
    }

    function getVerseWords(verse) {
        // Dividing a verse into words is more complicated than dividing at spaces
        // because psik/legarmeih is not its own word but divided by space,
        // and maqef divides words that are not divided by space.
        // (In both cases, considering them the part of the previous word for our purposes.)
        // FUTURE: do this in advance, not every time in the browser... it doesn't change
        // FUTURE: unit test
        // FUTURE: test this function over the entire Tanakh
        const verse2 = verse.replace(/\u05be/g, "\u05be ") // add a space after maqaf (need to remove it later)
        const candidateWords = verse2.split(" ")
        const words = candidateWords.reduce((acc, word) => {
            const paseq = "\u05c0"
            if (word === paseq) {
                acc[acc.length - 1] = acc[acc.length - 1] + ` ${paseq}`
            } else {
                acc.push(word)
            }
            return acc
        }, [])
        return words
    }

    function getVerse(verse, meanings) {
        // TODO: handle this on server side (or preprocess)
        // TODO: test ALL the text to make sure it works (and matches meaning data) - how did I do other testing?
        const verseWords = getVerseWords(verse)
        const taggedWords = verseWords.map((word, i) => {
            const meaning = meanings ? meanings[i] : "" // TODO: not even get here if we don't have data yet
            const url =
                "https://marcstober.github.io/HebrewLexicon/HomeFiles/Lexicon.html?lexID=" +
                meaning
            // FUTURE: It seems odd to have the regular content in an attribute. Better way? And does this create a lot of extra HTML?
            return (
                <Popup
                    trigger={<span className="word">{word}</span>}
                    position={["bottom center", "bottom right", "bottom left"]}
                    closeOnDocumentClick
                    arrowStyle={{ color: "black" }}
                >
                    <div className="popover-content">
                        <a href={url}>
                            Meaning<sup>beta</sup>
                        </a>
                    </div>
                </Popup>
            )
        })
        const taggedVerse = taggedWords.reduce((prev, curr, i) => {
            if (i > 0 && !endsWithMaqaf(verseWords[i - 1])) {
                prev.push(" ")
            }
            prev.push(curr)
            return prev
        }, [])
        return taggedVerse
    }

    function endsWithMaqaf(word) {
        try {
            return word.endsWith("\u05be")
        } catch (e) {
            console.log(word)
            throw e
        }
    }

    function getVerseElement(
        hebrewBookName,
        hebrewChapterNumber,
        hebrewVerseNumber
    ) {
        const meaning = getMeaning(
            hebrewBookName,
            hebrewChapterNumber,
            hebrewVerseNumber
        )
        return (
            <>
                {getVerse(
                    el.chapters[hebrewChapterNumber][hebrewVerseNumber],
                    meaning
                )}
            </>
        )
    }

    return (
        <div key={el.book_name} className="book">
            {/* hide book name since it's always in drop down <h1 key={el.id}>{el.book_name} {el.sub_book_name}</h1> */}
            {Object.keys(el.chapters).map((ch) => {
                if (
                    hebrew2int(ch) >= hebrew2int(selection.fromChapter) &&
                    hebrew2int(ch) <= hebrew2int(selection.toChapter)
                ) {
                    return (
                        <div key={ch}>
                            {Object.keys(el.chapters[ch]).map(
                                (hebrewVerseNumber) =>
                                    verseShouldDisplay(
                                        selection,
                                        ch,
                                        hebrewVerseNumber
                                    ) && (
                                        <div key={hebrewVerseNumber}>
                                            {
                                                /* show chapter number before new chapter in middle of a selection that spans multiple chapters */
                                                hebrewVerseNumber === "א" &&
                                                    selection.fromChapter !==
                                                        ch && <h2>פרק {ch}</h2>
                                            }
                                            {/* begin linear verse */}
                                            <div className="row">
                                                <div className="col">
                                                    <h3>{hebrewVerseNumber}</h3>
                                                    <div className="row">
                                                        <div className="col px-0">
                                                            {getNotesElement(
                                                                el.book_name,
                                                                ch,
                                                                hebrewVerseNumber
                                                            )}
                                                            <div className="row mb-0 the-text">
                                                                <div className="col-sm pb-2">
                                                                    <div className="mx-4">
                                                                        {getVerseElement(
                                                                            el.book_name,
                                                                            ch,
                                                                            hebrewVerseNumber
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm pb-4 stam">
                                                                    <div className="mx-4">
                                                                        {
                                                                            el
                                                                                .chapters[
                                                                                ch
                                                                            ][
                                                                                hebrewVerseNumber
                                                                            ]
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* end linear verse */}
                                        </div>
                                    )
                            )}
                        </div>
                    ) // end return
                } // end if
            })}
        </div>
    )
}

export default MainText
