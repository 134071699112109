import { hebrew2int } from './services/gematria';

// TODO: Future: use this in the JSX below.
const findBook = (bookName, data) => {
    return data.find((el) => { return bookName === el.book_name; });
}

const adjustSelection = (targetName, selection, data) => {
    // Sensible logic to prevent invalid selection (i.e., no verses) 
    // TODO: Future: also run when selection loaded/refeshed from URL? Would need default logic if no "targetName" 
    //  (regression test existing functionality - needs unit tests!)

    // TODO: Future: some animation when we programatically change the selection? Flash of lemon yellow in background of that dropdown? 

    // Prevent selecting to a chapter that doesn't exist, e.g., when switching to a book with fewer chapters.
    // Select the closest (i.e., last) chapter.
    const bookData = findBook(selection.bookName, data);
    if (!bookData) {
        // console.error("Book not found: ", selection.bookName);
        return;
    }
    if (!bookData.chapters.hasOwnProperty(selection.fromChapter)) {
        selection.fromChapter = Object.keys(bookData.chapters).at(-1);
    }
    if (!bookData.chapters.hasOwnProperty(selection.toChapter)) {
        selection.toChapter = Object.keys(bookData.chapters).at(-1);
    }
    // Prevent selecting a verse that doesn't exist, e.g., when switching to a chapter with fewer verses.
    // Select the closest (i.e., last verse).
    if (!bookData.chapters[selection.fromChapter].hasOwnProperty(selection.fromVerse)) {
        selection.fromVerse = Object.keys(bookData.chapters[selection.fromChapter]).at(-1);
    }
    if (!bookData.chapters[selection.toChapter].hasOwnProperty(selection.toVerse)) {
        selection.toVerse = Object.keys(bookData.chapters[selection.toChapter]).at(-1);
    }

    // The reason for considering the target name is that it would seem like a bug to not
    // "accept" the users change; so we have to change the OTHER dropdown.

    // If the user selects a different "from" chapter,
    // and it's AFTER the current "to" chapter,
    // adjust the "to" chapter to to be the same as the "from" chapter. 
    if (targetName === 'fromChapter' && hebrew2int(selection.toChapter) < hebrew2int(selection.fromChapter)) {
        selection.toChapter = selection.fromChapter;
    }

    // If the user selects a different "to" chapter,
    // and it's BEFORE the current "from" chapter,
    // adjust the "from" chapter to to be the same as the "to" chapter. 
    if (targetName === 'toChapter' && hebrew2int(selection.toChapter) < hebrew2int(selection.fromChapter)) {
        selection.fromChapter = selection.toChapter;
    }

    // If we are displaying verses within 1 chapter, do the same for the verses in that chapter...
    if (selection.toChapter === selection.fromChapter) {
        // If the user selects a different "to" verse,
        // and it's AFTER the current "to" verse,
        // adjust the "to" verse to to be the same as the "from" verse. 
        if (targetName === 'fromVerse' && hebrew2int(selection.toVerse) < hebrew2int(selection.fromVerse)) {
            selection.toVerse = selection.fromVerse;
        }

        // If the user selects a different "to" verse,
        // and it's BEFORE the current "from" verse,
        // adjust the "from" verse to to be the same as the "to" verse. 
        if (targetName === 'toVerse' && hebrew2int(selection.toVerse) < hebrew2int(selection.fromVerse)) {
            selection.fromVerse = selection.toVerse;
        }
    }
}

// module.exports = adjustSelection;
export default adjustSelection;