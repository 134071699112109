import './App.css';

import { useState, useEffect } from 'react';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import MainMenu from './MainMenu'
import Selector from './Selector';
import Search from './Search'
import MainText from './MainText';

import { hebrew2int, int2hebrew } from './services/gematria';

import adjustSelection from './adjustSelection';

// BUG: iOS ONLY: https://parsh.io/app/bereishit/47/7/-47/14, can't change to a different book. (Use inspect.dev or BrowserStack?)
// BUG? copyable link as input "prevents keyboard on iOS"??? [a note I scribbled...]

// FEATURE: some info/contact
// FEATURE: "share"
// FEATURE: Able to selected a range of verses within a book of the Torah.
// FEATURE: You can bookmark or share the current URL and use it to load the same verses.
// FEATURE: Responsive design that is function on mobile and desktop devices.
// FEATURE: Default to Shema (Deut. 6:4-9), for both Jewish education and usability reasons. 
//  For Jewish education, reinforce where Shema comes from Tanakh.
//  For usability, show something familiar to demonstrate ability to select a range of verses.
// FEATURE: Text from MAM.
// FEATURE: Linear tikkun-like presentation.
// FEATURE: Sensible logic for adjusting dropdowns (to prevent an invalid selection) when you change a previous dropdown.
// FEATURE: printable


//  while "devarim/6/4/-9" resolves to  "devarim/6/4/-9/29", etc.

// ## Additional requirements and design decisions ##
// ** these can inform testing **
// Additional requirement: Show the chapter number only when a new chapter starts in the middle of the selected range.
// Additional requirement: Should be able to change selection, navigate back, and navigate forward again.
// Additional requirement: Document title (tab) shows the current selection.

// TODO: FUTURE: Implied part of URL. e.g., "devarim/6/4/-/9" resolve to "devarim/6/4/-6/9", 
// TODO: Future: Optimize: don't download all data, full Boostrap, full fonts
// TODO: Future: Bootstrap RTL (margins s and e not working right?)
// TODO: Measuring performance: https://create-react-app.dev/docs/measuring-performance/
// TODO: Loading spinner or something? (e.g. loading full Bereishit)
// TODO: Zarka and segol at end of line cut off (font bug?)
// TODO: FUTURE: rest of Tanakh (note `sub_book_name` in source data)
// TOOO: FUTURE: gutter not margin for placement of verse number?
// TODO: Future: Make it work with WWW and STAGE URLs. See also: https://skryvets.com/blog/2018/09/20/an-elegant-solution-of-deploying-react-app-into-a-subdirectory/
//  * actually I'm not sure I really want it to work at www (will this also mess up Visitor Analytics?) but it should redirect (current done in app/.htaccess)
//  * but, I also think we could try to do without the PUBLIC_URL env variable - e.g., https://create-react-app.dev/docs/using-the-public-folder/ encouages
//    you to `import` (https://create-react-app.dev/docs/adding-images-fonts-and-files) 
// TODO: move out of the app folder? I think react apps are more simply deployed at the root, KISS. We can still have other subfolders that aren't the react app.
//  * and it might make more sense to the user
//  * if we do this before more users, probably not worthwhile to handle legacy links to /app/
// TODO: Future: loading spinner?
// TODO: Future: printing in Chrome (not FF) - why does it look more like a mobile view? Maybe there is logic to it? 
//    (This is why TropeTrainer says to print at A3? There must be a better solution... also cf. changing the scale in browser print settings)
// TODO: Fix printing issue with iOS safari?
// TODO: Future: print version has QR code of URL and warning about Shemot.
// TODO: Future: Bug that when you copy unpointed texts the points come back - actually remove points don't rely on font.
// TODO: Click outside main menu closes it, maybe even a close button
// TODO: Takhton/elyon presentation (and other fancy presentations) - don't forget Ex. 35:22! (actually Genesis not Exodus???)
// TODO: Link to AlHatorah.org ???
// TODO: copyable link should be square since it's not really an input (see above about iOS bug)
// TODO: Next.js?
// TODO: should menu ever print? (i.e. even if you ctrl-p when open)
// TODO: qr code: https://github.com/davidshimjs/qrcodejs?utm_source=cdnjs&utm_medium=cdnjs_link&utm_campaign=cdnjs_library 
// TODO: actual strip trope on left side - in particular, munach legarmeih/pasek shows wider space
// TODO: favicons : https://realfavicongenerator.net/

function App() {

  const books = [
    {
      section: 'Torah',
      books: [
        {
          hebrewName: 'ספר בראשית',
          transliteratedName: 'bereishit'
        },
        {
          hebrewName: 'ספר שמות',
          transliteratedName: 'shemot'
        },
        {
          hebrewName: 'ספר ויקרא',
          transliteratedName: 'vayikra'
        },
        {
          hebrewName: 'ספר במדבר',
          transliteratedName: 'bemidbar'
        },
        {
          hebrewName: 'ספר דברים',
          transliteratedName: 'devarim'
        }
      ]
    },
    {
      section: 'ChamMeg',
      books: [
        {
          hebrewName: 'מגילת אסתר',
          transliteratedName: 'esther'
        },
        {
          hebrewName: 'מגילת רות',
          transliteratedName: 'ruth'
        },
        {
          hebrewName: 'מגילת איכה',
          transliteratedName: 'eikhah'
        },
        {
          hebrewName: 'מגילת שיר השירים',
          transliteratedName: 'shir-hashirim'
        },
        {
          hebrewName: 'מגילת קהלת',
          transliteratedName: 'kohelet'
        }
      ]
    }
  ];

  const bookSectionIndex = books.reduce((acc, section) => {
    section.books.forEach(book => {
      acc[book.hebrewName] = section.section;
    });
    return acc;
  }, {});

  const flatBooks = books.reduce((acc, section) => { acc.push(...section.books); return acc; }, []);

  const transliteratedBookNames = flatBooks.reduce((acc, book) => {
    acc[book.hebrewName] = book.transliteratedName; return acc;
  }, {});

  // console.log(transliteratedBookNames);

  const [tanakhData, setTanakhData] = useState([]); // TODO: understand this (and cf. setState/componentDidMount stuff)
  const [selection, setSelection] = useState({}); // TODO: should this be inside the component?

  const onPopstate = (x) => { // handle browser forward/back
    const newSelection = getSelectionFromUrlOrDefault();
    setSelection(newSelection);
  };

  useEffect(() => {
    // console.log("entering useEffect")
    // console.log("selection: ", selection)
    let newSelection = selection;
    if (!selection.hasOwnProperty('bookName')) {
      newSelection = getSelectionFromUrlOrDefault();
    }
    const section = bookSectionIndex[newSelection.bookName];

    // console.log(`Checking if ${section} is in tanakhData...`)
    if (!tanakhData.hasOwnProperty(section)) {
      console.log(`Fetching ${section}...`)
      // TODO: axios?
      fetch(`${process.env.PUBLIC_URL}/MAM-${section}.json`)
        .then(response => response.json())
        .then(data => {
          tanakhData[section] = data;
          // console.log("setting tanakhData")
          // "React evaluates state changes by checking for shallow equality" (https://blog.logrocket.com/how-when-to-force-react-component-re-render/).
          // So, create new object (using spread operator) to trigger re-render.
          setTanakhData({
            ...tanakhData,
            [section]: data
          });

          if (newSelection.targetName) {
            adjustSelection(newSelection.targetName, newSelection, data);
          }
          setSelection({ ...newSelection });

        });
    }
  }, [tanakhData, selection]);

  // keep URL in sync with selection
  useEffect(() => {
    window.addEventListener('popstate', onPopstate)

    // return cleanup function - https://stackoverflow.com/a/60677531/119901
    return () => {
      window.removeEventListener('popstate', onPopstate);
    };
  }, []);




  const getSelectionFromUrlOrDefault = () => {
    // TODO: FUTURE: not this hack
    let pathname = window.location.pathname;
    if (window.location.pathname.startsWith('/app')) {
      pathname = pathname.substr(4);
    }
    if (pathname === '') { // this is happening on dev server
      pathname = '/';
    }

    // TODO: not this hack
    if (pathname === '/search') {
      return {
        bookName: 'ספר דברים'
      };
    }

    if (pathname === '/') {
      return {
        bookName: 'ספר דברים',
        fromChapter: 'ו',
        fromVerse: 'ד',
        toChapter: 'ו',
        toVerse: 'ט'
      };
    }
    var parts = pathname.split('/');
    var hebrewBookName = Object.keys(transliteratedBookNames).find(key => transliteratedBookNames[key] === parts[1]);
    return {
      bookName: hebrewBookName,
      fromChapter: int2hebrew(parts[2]),
      fromVerse: int2hebrew(parts[3]),
      toChapter: int2hebrew(parts[4].replace('-', '')), // '-' is for future use
      toVerse: int2hebrew(parts[5])
    };
  };

  useEffect(() => {
    if (window.location.pathname.includes('/search')) {
      return;
    }
    if (selection.bookName) {
      let base = "/"
      // TODO: FUTURE: not this hack
      if (window.location.pathname.startsWith('/app')) {
        base = "/app/";
      }
      const url = base + transliteratedBookNames[selection.bookName] + "/" + hebrew2int(selection.fromChapter) + "/" +
        hebrew2int(selection.fromVerse) + "/-" + hebrew2int(selection.toChapter) + "/" + hebrew2int(selection.toVerse)
      if (window.location.pathname !== url) { // prevent bug preventing navigating forward after back
        window.history.pushState({}, '', url);
      }
      const shortBookName = selection.bookName.substr(selection.bookName.indexOf(' ') + 1);
      document.title = `${shortBookName} ${selection.fromChapter}:${selection.fromVerse}-${selection.toChapter}:${selection.toVerse} | parsh.io`;
    }
  }, [selection]);

  const handleSelectionChange = (newSelection) => {
    // console.log(newSelection); // debugging
    console.log("targetName in handleSelectionChange: ", newSelection.targetName)
    setSelection(newSelection);
  };




  // TODO: this should be a component (is that just changing to title case?) and moved to its own file ?
  const getDefaultRouteElement = () => {
    // console.log("bsi: ", bookSectionIndex, "selection: ", selection, "tanakhData: ", tanakhData)
    // console.log(bookSectionIndex[selection.bookName])
    // console.log(tanakhData[bookSectionIndex[selection.bookName]])
    if (!selection.bookName) {
      return "";
    }
    if (!tanakhData[bookSectionIndex[selection.bookName]]) {
      // console.log("No tanakhData for ", selection.bookName)
      return "";
    }
    // console.log("yes")
    return (
      <>
        <div className="py-3 selector-wrapper">
          <Selector data={tanakhData[bookSectionIndex[selection.bookName]]} onSelection={handleSelectionChange} selection={selection} books={books}></Selector>
        </div>
        {tanakhData[bookSectionIndex[selection.bookName]].map(el => {
          if (selection.bookName === el.book_name) {
            return <MainText el={el} selection={selection} transliteratedBookNames={transliteratedBookNames} />         
          }
          else {
            return false; // TODO: is this necessary?
          }
        })}
      </>
    );
  };

  return (
    <div className="App container" dir="rtl">
      <header>
        <MainMenu />
      </header>
      <main>
        <BrowserRouter>
          <Routes>
            <Route path="/app/search" element={<Search data={tanakhData} />} />
            <Route path="*" element={getDefaultRouteElement()} />
          </Routes>
        </BrowserRouter>
      </main>
    </div>
  );
}

export default App;
