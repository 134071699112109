import gematria from 'gematria'; // TODO: Future: Get rid of this dependency?

function hebrew2int(h) {
  return gematria(h).toMisparHaPanim()
}

const int2hebrew = (n) => {
    const lookup = {
      0: '',
      1: 'א',
      2: 'ב',
      3: 'ג',
      4: 'ד',
      5: 'ה',
      6: 'ו',
      7: 'ז',
      8: 'ח',
      9: 'ט',
      10: 'י',
      20: 'כ',
      30: 'ל',
      40: 'מ',
      50: 'נ',
      60: 'ס',
      70: 'ע',
      80: 'פ',
      90: 'צ',
      100: 'ק',
      200: 'ר',
      300: 'ש',
      400: 'ת'
    }

    const ones = n % 10;
    const tens = n % 100 - ones;
    const hundreds = n % 1000 - tens - ones;

    if (tens + ones === 15) {
      return lookup[hundreds] + 'טו';
    } 
    else if (tens + ones === 16) {
      return lookup[hundreds] + 'טז';
    }
    return lookup[hundreds] + lookup[tens] + lookup[ones];
  }

export { hebrew2int, int2hebrew };